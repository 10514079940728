import axios from 'axios'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus';
import router from '../router';
// const router = useRouter();
// const defaultBaseUrl = '//192.168.3.105:10000'
// const defaultBaseUrl = '//192.168.3.102:10000'
// const defaultBaseUrl = '//118.178.231.37:9091'
// const defaultBaseUrl = '//test.xyr.ikbh.top:9999'
const defaultBaseUrl = '//back.zhwapp.com'
// create an axios instance

const service = axios.create({
    withCredentials: false,
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    baseURL: `${window.location.protocol}${defaultBaseUrl}`,
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 30000, // request timeout
    headers: { 'Content-Type': 'application/json; charset=UTF-8' }
})
// request interceptor
service.interceptors.request.use(
    (config) => {
        let token = window.localStorage.getItem('customToken')
        // console.log('%c Line:6 🍧 config', 'color:#ffdd4d', config)
        if (token && token != 'undefined') {
            token = JSON.parse(token)
        } else {
            token = ''
        }
        config.headers = {
            'Content-Type': 'application/json; charset=UTF-8',
            Token: token,
            Authorization: token
        }
        if (config.url == '/captchaImage') {
            config.headers = {
                'Content-Type': 'application/json; charset=UTF-8'
            }
        }
        // do something before request is sent
        return config
    },
    (error) => {
        // do something with request error
        console.log('request error', error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    (response) => {
        // console.log("%c Line:59 🥔 response", "color:#e41a6a", response);
        const res = response.data
        const code = res.code
        // if the custom code is not 20000, it is judged as an error.
        if (code >= 400) {
            if (code == 401) {
                // window.localStorage.clear()
                // 登录超时
                // ElMessageBox.confirm('登录超时，请重新登录', '提示', {
                //     confirmButtonText: '确认',
                //     cancelButtonText: '取消',
                //     type: 'warning'
                // })
                //     .then(() => {
                //       // router
                //       // console.log("%c Line:74 🍎 router", "color:#93c0a4", router);

                //         router.push({
                //             path: '/login'
                //         })
                //     })
                //     .catch(() => {
                //         // window.localStorage.clear()
                //     })
                return Promise.reject(res)
            } else {
                if (code == 500) {

                }
                return Promise.reject(res)
            }

        } else {
            return res
        }
    },
    (error) => {

        return Promise.reject(error.data)
    }
)

export default service
